


















import {Component, Ref, Mixins} from 'vue-property-decorator'

import {InputMixin, InputMixinOptions} from './inputMixin'

export type DbiInputOptions = InputMixinOptions & {
    type?: string
    placeholder?: string
}

const OPTION_DEFAULTS: Partial<DbiInputOptions> = {
    type: 'text',
}

@Component({
    name: 'DbiInput',
})
export default class DbiInput extends Mixins<InputMixin<DbiInputOptions, string>>(InputMixin) {
    @Ref()
    private readonly label!: HTMLLabelElement

    get innerOptions(): DbiInputOptions {
        return Object.assign({}, OPTION_DEFAULTS, this.options)
    }
}
