























































import {Vue, Component} from 'vue-property-decorator'

import DbiInput from '@common/components/form/DbiInput.vue'
import StationSelect from '@/components/form/StationSelect.vue'

import {sendRpcRequest} from '@common/api'
import type {RpcPayload, LoginResponse} from '@common/api'

const SSO_DATA = {
    URL: process.env.VUE_APP_SSO_URL as string,
    CLIENT_ID: process.env.VUE_APP_SSO_CLIENT_ID as string,
    REDIRECT_URI: process.env.VUE_APP_SSO_REDIRECT_URI as string,
} as const

const SSO_LINK = `${SSO_DATA.URL}?scope=Username&redirect_uri=${SSO_DATA.REDIRECT_URI}&client_id=${SSO_DATA.CLIENT_ID}&token_content_type=jwt&response_type=code`

@Component({
    name: 'Login',
    components: {
        DbiInput,
        StationSelect,
    },
})
export default class Login extends Vue {
    ssoLink = SSO_LINK
    error = ''
    errorMsg = ''

    loginPayload = {
        username: '',
        password: '',
        deviceId: 'bl-sv1-pia001',
    }

    showStationSelect = false
    showText = false

    get preventLogin(): boolean {
        const {username, password, deviceId} = this.loginPayload
        return !(username && password && deviceId)
    }

    get station(): Dbi.Station | null {
        return this.$dbiStore.state.homeStation
    }

    set station(station: Dbi.Station | null) {
        this.showStationSelect = false
        if (station) {
            this.$dbiStore.actions.homeStation(station)
        }
    }

    created(): void {
        const {query} = this.$route
        if (query.error) {
            this.setError(query.error as string)
        }

        if (query.code) {
            this.sendCode(query.code as string)
        }
        this.showText = !query.code || !!query.error

        this.forwardUser()
    }

    async sendCode(code: string): Promise<void> {
        const data = await this.sendRequest({
            method: 'User.loginOauth',
            params: [
                {
                    code,
                    redirect: SSO_DATA.REDIRECT_URI,
                },
            ],
            isDynamicDevice: true,
            deviceNumber: null,
            token: null,
            timestamp: Date.now(),
        })
        if (data) {
            this.finishLogin({token: data.token, deviceId: data.result.dynamicDeviceId})
        }
    }

    async sendOldLogin(): Promise<void> {
        if (this.preventLogin) {
            return
        }
        const {username, password, deviceId} = this.loginPayload
        const data = await this.sendRequest({
            deviceNumber: deviceId,
            method: 'User.login',
            params: [{username, password}],
            token: null,
        })
        if (data) {
            this.finishLogin({token: data.token, deviceId})
        }
    }

    async sendRequest(payload: RpcPayload): Promise<void | LoginResponse> {
        try {
            const data = await sendRpcRequest(payload)
            if (data.error) {
                this.setError(data.error.message)
                return
            }
            return data
        }
        catch (error) {
            this.$log.error(error)
            this.setError(error)
        }
    }

    setError(error: string): void {
        this.error = error

        this.errorMsg =
            error === 'SSO_GROUP_NOT_AUTHORIZED'
                ? 'Sie haben keine Berechtigung für die Nutzung der Pia App. Bitte klären Sie die Genehmigung mit ihrer Führungskraft und beantragen Sie den Zugriff in iMan.'
                : 'Der Anmeldevorgang ist fehlgeschlagen. Bitte versuchen Sie es erneut.'
    }

    finishLogin(payload: Parameters<this['$dbiStore']['actions']['login']>[0]): void {
        this.$dbiStore.actions.login(payload)
        this.forwardUser()
    }

    forwardUser(): void {
        const {
            state: {token, deviceId},
            getters,
        } = this.$dbiStore
        if (getters.readyToOpen) {
            this.$router.replace({name: 'station-panel'})
            return
        }
        this.showStationSelect = !!(token || deviceId) && !this.station
    }
}
