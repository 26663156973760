import {Component, Vue, Prop, Model} from 'vue-property-decorator'

export type InputMixinOptions = {
    id: string
    name?: string
    readonly?: boolean
}

@Component
export class InputMixin<Options extends InputMixinOptions, ValueType = unknown> extends Vue {
    @Model('input')
    protected readonly value!: ValueType

    @Prop({type: Object, required: true})
    readonly options!: Options

    get innerOptions(): Options {
        return Object.assign({}, this.options)
    }

    get innerName(): string {
        const {id, name} = this.innerOptions
        return name || id
    }

    get clazz(): Record<string, boolean> {
        return {
            [`input-field-${this.innerOptions.id}`]: true,
        }
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    get inputListeners(): Record<string, MaybeArray<Function>> {
        const send = this.sendInput.bind(this)
        return {
            ...this.$listeners,
            input: send,
            change: send,
        }
    }

    sendInput(event: InputEvent): void {
        const {value} = event.target as HTMLInputElement
        this.$emit('change', value)
        this.$emit('input', value)
    }
}
